

// const linkBackend = (complemento) => {
//     return `http://localhost:4000${complemento}`
// }

// const linkBackend = (complemento) => {
//     return `https://gvb78hr5-4000.use2.devtunnels.ms${complemento}`
// }

const linkBackend = (complemento) => {
    return `https://backend-proyecto-galileo-app.onrender.com${complemento}`
}


export {linkBackend};