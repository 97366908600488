import trasera1 from '../img/Imagenes-tarjeta-bloqueada/trasera-1.png';
import trasera2 from '../img/Imagenes-tarjeta-bloqueada/trasera-2.png';
import trasera3 from '../img/Imagenes-tarjeta-bloqueada/trasera-3.png';
import trasera4 from '../img/Imagenes-tarjeta-bloqueada/trasera-4.png';
import trasera5 from '../img/Imagenes-tarjeta-bloqueada/trasera-5.png';
import trasera6 from '../img/Imagenes-tarjeta-bloqueada/trasera-6.png';
import trasera7 from '../img/Imagenes-tarjeta-bloqueada/trasera-7.png';
import trasera8 from '../img/Imagenes-tarjeta-bloqueada/trasera-8.png';
import trasera9 from '../img/Imagenes-tarjeta-bloqueada/trasera-9.png';
import trasera10 from '../img/Imagenes-tarjeta-bloqueada/trasera-10.png';
import trasera11 from '../img/Imagenes-tarjeta-bloqueada/trasera-11.png';
import trasera12 from '../img/Imagenes-tarjeta-bloqueada/trasera-12.png';

const arrayImagenes = [ trasera1, trasera2, trasera3, trasera4, trasera5, trasera6, trasera7, trasera8, trasera9, trasera10, trasera11, trasera12];


const estiloAleatorio = () => {
    const numeroAleatorio = Math.floor(Math.random() * arrayImagenes.length);

    return arrayImagenes[numeroAleatorio];
}

export { estiloAleatorio };






