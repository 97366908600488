import React from 'react'
import { motion } from 'framer-motion';
import ListaTarjetas from '../components/Premios/ListaTarjetas'

const Premios = (props) => {
    const { usuario } = props;

    return (
        <motion.div
            transition={{duration: 1}}

            animate={{
                    opacity: ["0%", "100%"]
            }}
        >
            <ListaTarjetas usuario={usuario}/>
        </motion.div>
    )
}

export default Premios;