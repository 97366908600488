//Apartado para manejar las funciones globales.

import { linkBackend } from "./linkBackend"

//Funcion de borrado de datos.
const borrarAcceso = (_id, pregunta, url, mensajeconfirmacion, mensajeError, Swal) => {
    Swal.fire({
        title: pregunta,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Eliminar',
        denyButtonText: `No eliminar`,
        }).then((result) => {
            if (result.isConfirmed) {
                try{
                    let config = {
                        method: 'DELETE',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('Token')
                        },body: JSON.stringify({
                            _id: _id,
                        })
                    }

                    fetch(url, config)
                    .then(response => response.json())
                    .then((data) => {
                        if(data.mensaje === mensajeconfirmacion){
                            Swal.fire(
                                'Hecho',
                                mensajeconfirmacion,
                                'success'
                            )
                        }else if(data.mensaje === mensajeError){
                            Swal.fire(
                                'Error',
                                mensajeError,
                                'error'
                            )
                        }
                    })
                }catch (error){
                    console.error("Error al ejecutar el fetch.")
                }
            } else if (result.isDenied) {
                Swal.fire('No se eliminó el acceso ', '', 'info')
            }
        })
}


//Funcion para subir un juego, si la cuenta es masiva verifica si ese juego ya se paso y da una puntuacion establecida por repetir y si no lo paso agrega el juego a la lista de juegos completos y suma los puntos por pasarselo la primera vez
const desbloquearJuego = (_id, juego, puntos, puntosRepeticion, cuentaMasiva) => {
    if(cuentaMasiva){
        let puntosActuales = localStorage.getItem('puntosSeguimiento');
        let juegosActuales =  JSON.parse(localStorage.getItem('aplicativosCompletos'));


        if(juegosActuales.includes(juego)){
            let procesadoPuntos = Math.floor(puntosActuales) + puntosRepeticion;

            localStorage.setItem("puntosSeguimiento", procesadoPuntos);
        }else{
            juegosActuales.push(juego);
            let procesadoPuntos = Math.floor(puntosActuales) + puntos;

            localStorage.setItem("aplicativosCompletos", JSON.stringify(juegosActuales));
            localStorage.setItem("puntosSeguimiento", procesadoPuntos);

        }
    }else if(!cuentaMasiva){
        try{
            let config = {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Token')
                },body: JSON.stringify({
                    _id: _id,
                    juego: juego,
                    puntos: puntos,
                    puntosRepeticion: puntosRepeticion
                })
            }

            fetch(linkBackend("/api/login-usuarios/desbloqueo"), config)
            .then(response => response.json())
            .then((data) => {
                if(data.mensaje === "Juegos actualizados correctamente."){
                    console.log("Juego desbloqueado correctamente");
                }else if(data.mensaje === "Error al actualizar los juegos."){
                    console.log("Hubo un error al desbloquear juego");
                }else if(data.mensaje === "Juego ya desbloqueado"){
                    console.log("Juego ya desbloqueado");
                }
            })
        }catch (error){
            console.error("Fallo la llamada a la api");
        }
    }
}


const desbloquearTarjeta = (_id, tarjeta, cuentaMasiva) => {

    if(cuentaMasiva){
        let tarjetasActuales =  JSON.parse(localStorage.getItem('tarjetasDesbloqueadas'));

        if(tarjetasActuales.includes(tarjeta)){
        }else{
            tarjetasActuales.push(tarjeta);

            localStorage.setItem("tarjetasDesbloqueadas", JSON.stringify(tarjetasActuales));
        }
    }else if(!cuentaMasiva){
        try{
            let config = {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Token')
                },body: JSON.stringify({
                    _id: _id,
                    tarjeta: tarjeta
                })
            }
            fetch(linkBackend("/api/login-usuarios/desbloqueo-tarjetas"), config)
            .then(response => response.json())
            .then((data) => {
                if(data.mensaje === "Tarjetas actualizadas."){
                    console.log("Tarjeta desbloqueada correctamente");
                }else if(data.mensaje === "Error al actualizar las tarjetas."){
                    console.log("Hubo un error al desbloquear tarjeta");
                }else if(data.mensaje === "Tarjeta desbloqueada antes."){
                    console.log("Tarjeta desbloqueada antes.");
                }
            })
        }catch (error){
            console.error("Fallo en endpoint de tarjetas.");
        }
    }
}


const sumarMetrica = (motivo) => {
    console.log("Metrica", motivo);
    try{
        let config = {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Token')
            },body: JSON.stringify({
                motivo: motivo
            })
        }
        fetch(linkBackend("/api/metricas"), config)
        .then(response => response.json())
        .then((data) => {
            if(data.mensaje === "Sumado un ingreso exitosamente." || data.mensaje === "Tarjeta sumada correctamente."){
                console.log("Metricas actualizadas correctamente.");
            }else{
                console.error("Error al procesar metricas.");
            }
        })
    }catch (error){
        console.error("Fallo en endpoint de metricas.");
    }
}


const sumarMetricaJuegos = (juego, motivo) => {
    console.log("Metrica", motivo, juego);
    try{
        let config = {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Token')
            },body: JSON.stringify({
                juego: juego,
                motivo: motivo
            })
        }
        fetch(linkBackend("/api/metricas/juego"), config)
        .then(response => response.json())
        .then((data) => {
            if(data.mensaje === "Se agrego la metrica de juego abierto" || data.mensaje === "Se añadio la metrica de juego terminado"){
                console.log("Metricas actualizadas correctamente.");
            }else{
                console.error("Error al procesar metricas.");
            }
        })
    }catch (error){
        console.error("Fallo en endpoint de metricas.");
    }
}
export { borrarAcceso, desbloquearJuego, desbloquearTarjeta, sumarMetrica, sumarMetricaJuegos };