
//Fondos
import fondo1 from '../img/tarjetas/fondo-1.png';

//Personajes
import personaje1 from '../img/tarjetas/personaje-1.png';



let tarjetasPremio = [
    {
        nombre:"tarjeta1",
        imagenFondo: fondo1,
        imagenPersonaje: personaje1,
        precio: 500
    },
    {
        nombre:"tarjeta2",
        imagenFondo: fondo1,
        imagenPersonaje: personaje1,
        precio: 1000
    },
    {
        nombre:"tarjeta3",
        imagenFondo: fondo1,
        imagenPersonaje: personaje1,
        precio: 1500
    },
    {
        nombre:"tarjeta4",
        imagenFondo: fondo1,
        imagenPersonaje: personaje1,
        precio: 2000
    },
    {
        nombre:"tarjeta5",
        imagenFondo: fondo1,
        imagenPersonaje: personaje1,
        precio: 2500
    },
    {
        nombre:"tarjeta6",
        imagenFondo: fondo1,
        imagenPersonaje: personaje1,
        precio: 3000
    },
    {
        nombre:"tarjeta7",
        imagenFondo: fondo1,
        imagenPersonaje: personaje1,
        precio: 3500
    },
];

export {tarjetasPremio};