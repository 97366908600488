import React from 'react'
import AnimacionLoginMovil from './NavBar/AnimacionLoginMovil'

const Ensayo = () => {
    return (
        <div>
            <AnimacionLoginMovil/>
        </div>
    )
}

export default Ensayo