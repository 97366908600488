import React from 'react';
import ListaMiniJuegos from '../components/Menu/ListaMiniJuegos';

const Menu = (props) => {
    const { usuario } = props

    return (
        <div style={{width: "100%"}}>
            <ListaMiniJuegos usuario={usuario}/>
        </div>
    )
}

export default Menu