import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { linkBackend } from "../../lib/linkBackend";

//Imagenes
import logoPrincipal from "../../img/Index-login/logo-galileo.png";
import usuarioIcono from "../../img/Index-login/icons8-user-24.png";
import AnimacionLoginMovil from "../NavBar/AnimacionLoginMovil";


const IndexMovil = (props) => {
  const [errorAutenticacion, setErrorAutenticacion] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [mensajeCarga, setMensajeCarga] = useState("");
  const [ animacionIndex, setAnimacionIndex ] = useState(true);

  const { usuario, setLoginIn, loginIn } = props;

  let navegar = useNavigate();

  //Verificamos si hay un usuario logueado en el navegador y vamos directamente al admin.
  useEffect(() => {
    if (usuario) {
      navegar("/home/menu");
    }
  }, [usuario]);

  //Definimos si en el estado errorAutenticacion hay un mensaje que despues de 4 segundos vuelva a ser null para que desaparezca de la pantalla.
  if (errorAutenticacion) {
    setTimeout(() => {
      setErrorAutenticacion(null);
    }, 4000);
  }

  useEffect(() => {
    if (cargando === true && errorAutenticacion === null) {
      setMensajeCarga(<div className="index-movil__div--contenedor-loader"><div className="loader" id="loader"></div></div>);
    } else if (errorAutenticacion) {
      setMensajeCarga("");
      setCargando(false);
    }
  }, [cargando, errorAutenticacion]);


  //Timer que se ejecuta para desactivar el componente de animacion
  useEffect(() => {
    setTimeout(() => {
      setAnimacionIndex(false);
    }, 7000);
  }, []);

  return (
    <>
      <div className="index-movil__div--container">
      {animacionIndex ? (<AnimacionLoginMovil/>):("")}
        <div className="">
          <div className="index-movil__div--container-logo">
            <img src={logoPrincipal} alt="logo-principal"/>
          </div>
          <Formik
            initialValues={{
              usuario: "",
              contrasena: "",
            }}
            validate={(valores) => {
              let errores = {};

              if (!valores.usuario) {
                errores.usuario = "Introduzca un usuario valido";
              }

              if (!valores.contrasena) {
                errores.contrasena = "Introduzca una contraseña correcta";
              }

              return errores;
            }}
            onSubmit={async (valores) => {
              if (localStorage.getItem("usuario") !== valores.usuario) {
                localStorage.setItem("_id", "Vacio");
                localStorage.setItem("usuario", "Vacio");
                localStorage.setItem("nombre", "Vacio");
                localStorage.setItem("puntosSeguimiento", 0);
                localStorage.setItem(
                  "aplicativosCompletos",
                  JSON.stringify([])
                );
                localStorage.setItem(
                  "tarjetasDesbloqueadas",
                  JSON.stringify([])
                );
              }
              setCargando(true);
              try {
                let config = {
                  method: "POST",
                  headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    usuario: valores.usuario,
                    contrasena: valores.contrasena,
                  }),
                };
                let res = await fetch(
                  linkBackend("/api/login-usuarios/autenticacion"),
                  config
                );
                let json = await res.json();
                let authorizationValue = res.headers.get("Authorization");

                if (json.mensaje === "Este Usuario no existe") {
                  setErrorAutenticacion("Este Usuario no existe");
                } else if (
                  json.mensaje === "Usuario y contraseña incorrectos"
                ) {
                  setErrorAutenticacion("Usuario y contraseña incorrectos");
                } else if (
                  json.mensaje === "Usuario autenticado correctamente"
                ) {
                  //Verificar si hubo un acceso para que traiga el usuario al estado para acceder a la pagina.
                  if (loginIn === false) {
                    setLoginIn(true);
                  } else if (loginIn === true) {
                    setLoginIn(false);
                  }

                  //Guardando el token en la store del navegador.
                  localStorage.setItem("Token", authorizationValue);

                  setTimeout(() => {
                    navegar("/home/menu");
                  }, 1000);
                }
              } catch (error) {
                console.error("Error con el login", error);
              }
            }}
          >
            {({ errors }) => (
              <Form className="">
                {mensajeCarga ? (
                  mensajeCarga
                ) : (
                  <>

                    <div className="index-movil__div--inputs-container">
                    <div className="index-movil__img--imagen-usuario-container">
                      <img className="index-movil__img--imagen-usuario" src={usuarioIcono} alt="usuario"/>
                    </div>
                      <div className="">
                        <Field
                          data-atropos-offset="2"
                          type="text"
                          id="usuario"
                          name="usuario"
                          placeholder="Introducir usuario"
                          className="index-movil__field--input-usuario"
                        />
                      </div>
                      <div className="">
                        <Field
                          data-atropos-offset="2"
                          type="password"
                          id="contrasena"
                          name="contrasena"
                          placeholder="Introducir contraseña"
                          className="index-movil__field--input-contrasena"
                        />
                      </div>
                      <div
                        className="index-movil__div--container-button-submit"
                      >
                        <button type="submit">Entrar</button>
                      </div>
                    </div>


                    <ErrorMessage
                      name="contrasena"
                      component={() => (
                        <h4 className="index-movil__h4--error index-movil__h4--contrasena">
                          {errors.contrasena}
                        </h4>
                      )}
                    />
                    <ErrorMessage
                      name="usuario"
                      component={() => (
                        <h4 className="index-movil__h4--error index-movil__h4--usuario">
                          {errors.usuario}
                        </h4>
                      )}
                    />
                    {errorAutenticacion ? (
                      <h4 className="index-movil__h4--error index-movil__h4--usuario">
                        {errorAutenticacion}
                      </h4>
                    ) : (
                      ""
                    )}
                    {mensajeCarga}
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default IndexMovil