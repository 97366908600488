import React, { useEffect, useState } from 'react';
import { linkBackend } from '../lib/linkBackend';
import { useNavigate } from 'react-router-dom';


//Imagenes
import logo from './../img/globales/logo-aplicativo.png';

const CargaInicial = (props) => {
    const [ cargaDatos, setCargaDatos ] = useState(false);

    const { usuario } = props;

    let navegar = useNavigate();

    useEffect(() => {
        fetch(linkBackend('/api/login-usuarios/activar-servidor'), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
            })
            .then(res => res.json())
            .then(res => {if(res.mensaje === "Servidor Activo"){
                setCargaDatos(true);
            }else{
                console.error("Error al activar servidor");
            }})
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        if(cargaDatos){
            setTimeout(() => {
                if(usuario){
                    navegar('/home/menu');
                }else if(!usuario){
                    navegar('login');
                }
            }, 5000);
        }
    }, [cargaDatos, usuario]);



    return (
        <div className='cargaInicial__div--container'>
            <div className='cargaInicial__img--fondo-degradado'/>
            <div className='cargaInicial__img--fondo-mar'/>
            <img className='cargaInicial__img--logo-aplicativo'  src={logo} alt='logo-principal'/>
            <div className='cargaInicial__div--animacion cargaInicial__div--animacion1'></div>
            <div className='cargaInicial__div--animacion cargaInicial__div--animacion2'></div>
            <div className='cargaInicial__div--animacion cargaInicial__div--animacion3'></div>
            <div className='cargaInicial__div--cargando-container'>
                <p className='primeras'>C</p><p className='segundas'>A</p><p className='primeras'>R</p><p className='segundas'>G</p><p className='primeras'>A</p><p className='segundas'>N</p><p className='primeras'>D</p><p className='segundas'>O</p><p className='primeras'>.</p><p className='segundas'>.</p><p className='primeras'>.</p>
            </div>
        </div>
    )
}

export default CargaInicial;