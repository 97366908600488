import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import ReactHowler from 'react-howler';

import { desbloquearTarjeta, sumarMetrica } from '../../lib/funcionesGlobales';
import { estiloAleatorio } from '../../lib/imagenesTraseras';


//Sonidos
import hoverTarjetaBloqueada from '../../sounds/tarjetas-bloqueadas/tarjeta-bloqueada-hover.mp3';

const TarjetaBloqueada = (props) => {
    const [ canjeoInsuficiente, setCanjeoInsuficiente ] = useState(false);
    const [ estiloElegido, setEstiloElegido ] = useState("");
    const [ sonidoHoverTarjeta, setSonidoHoverTarjeta ] = useState(false);

    //Props
    const { usuario, tarjeta, precio, cuentaMasiva, setTarjetaDesbloquear } = props;



    const procesarCanjeoTarjeta = () => {
        if(precio <= usuario.puntosSeguimiento){
            console.log("Canjeo esp pago", precio, usuario, tarjeta);
            sumarMetrica("tarjetas")
            desbloquearTarjeta(usuario._id, tarjeta, cuentaMasiva);
            setTarjetaDesbloquear(tarjeta);
        }else{
            console.log("Canjeo esp no pago", precio, usuario, tarjeta);
            setCanjeoInsuficiente(true);
            setTimeout(() => {
                setCanjeoInsuficiente(false);
            }, 3000);
        }
    }


    useEffect(() => {
        setEstiloElegido(estiloAleatorio());
    }, [])


    const howlerRef = useRef(null);


    useEffect(() => {
        if(!sonidoHoverTarjeta){
            reiniciarSonido()
        }
    }, [sonidoHoverTarjeta]);


    const reiniciarSonido = () => {
        if(howlerRef.current){
            howlerRef.current.seek(0);
        }
    }

    const desactivarSonido = () => {
        setSonidoHoverTarjeta(false);
    }


    return (
        <div className='tarjeta-bloqueada__div--container'>
            <ReactHowler ref={howlerRef} onEnd={desactivarSonido} src={hoverTarjetaBloqueada} playing={sonidoHoverTarjeta}/>
            <motion.button
                onMouseEnter={() => setSonidoHoverTarjeta(true)}
                onMouseLeave={() => setSonidoHoverTarjeta(false)}
                transition={{
                    duration: 0.3
                }}

                whileHover={{ scale: 1.2 }}

                whileTap={{ scale: 0.8 }}

                className='tarjeta-bloqueada__button'
                onClick={() => procesarCanjeoTarjeta()}>
                <img src={estiloElegido} alt={tarjeta}/>
                <p className='tarjeta-bloqueada__p--precio'>{precio}</p>
                <p className='tarjeta-bloqueada__p--reclamar'>Reclamar</p>
            </motion.button>
            {canjeoInsuficiente ? (<motion.p

            transition={{
                duration: 1
            }}

            animate={{
                borderRadius: ["0%", "100%", "50%", "100%"],
                scale: [0.8, 1, 0.8, 1]
            }}
            className='tarjeta-bloqueada__p--monedas-insuficientes'>Monedas insuficientes</motion.p>):("")}
        </div>
    )
}

export default TarjetaBloqueada