import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { linkBackend } from '../../lib/linkBackend';
import { tarjetasPremio } from '../../lib/ListaTarjetas';
import TarjetaDesbloqueada from './TarjetaDesbloqueada';
import TarjetaBloqueada from './TarjetaBloqueada';
import TransicionTarjeta from './TransicionTarjeta';

const ListaTarjetas = (props) => {
    const { usuario } = props;

    const [ datosUsuario, setDatosUsuario ] = useState(false);
    const [ carga, setCarga ] = useState(false);
    const [ error, setError ] = useState("");
    const [ tarjetaDesbloquear, setTarjetaDesbloquear ] = useState(false);

    useEffect(() => {

        let intervalo = setInterval(() => {
            if(usuario.cuentaMasiva){
                setCarga(true);
                setDatosUsuario({puntosSeguimiento: localStorage.getItem('puntosSeguimiento'), tarjetasDesbloqueadas: JSON.parse(localStorage.getItem('tarjetasDesbloqueadas'))})
            }else{
                try{
                    let config = {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('Token')
                        },body: JSON.stringify({
                            _id: usuario._id
                        })
                    }
                    fetch(linkBackend("/api/login-usuarios/datos-usuario"), config)
                    .then(response => response.json())
                    .then((data) => {
                        if(data){
                            setCarga(true);
                            setDatosUsuario(data);
                        }
                    })
                }catch (error){
                    setCarga(true);
                    setError(error);
                    console.error("Error al ejecutar el fetch.")
                }
            }
        }, 1000);

        return () => {
            clearInterval(intervalo);
        }
    }, []);


    let componenteTarjetas;
    //Creamos las tarjetas segun la cantidad de usuarios en la base de datos.
    if(error){
        componenteTarjetas = [<h2>Error: {error.message}</h2>];
    }else if(!carga){
        componenteTarjetas = [
        <div className='lista-tarjetas__div--container--cargador'>
            <div className="loader" id="loader"></div>
        </div>];
    }else if(carga){
        componenteTarjetas = tarjetasPremio.map(tarjeta => {
            return (datosUsuario.tarjetasDesbloqueadas.includes(tarjeta.nombre)) ? (<TarjetaDesbloqueada tarjetaDesbloquear={tarjetaDesbloquear} fondo={tarjeta.imagenFondo} personaje={tarjeta.imagenPersonaje} precio={tarjeta.precio} nombre={tarjeta.nombre}><TransicionTarjeta tarjeta={tarjeta.nombre} tarjetaDesbloquear={tarjetaDesbloquear}/></TarjetaDesbloqueada> ):(<TarjetaBloqueada setTarjetaDesbloquear={setTarjetaDesbloquear} tarjeta={tarjeta.nombre} precio={tarjeta.precio} usuario={datosUsuario} cuentaMasiva={usuario.cuentaMasiva}/>)
        })
    };



    return (
        <motion.div
            transition={{duration: 1}}

            animate={{
                    opacity: ["0%", "100%"]
            }}
            className='lista-tarjetas__div--container'
        >
            <div className='lista-tarjetas__div--imagen-fondo'></div>
            <div className='lista-tarjetas__div--grid-tarjetas'>
                {componenteTarjetas}
            </div>
        </motion.div>
    )
}

export default ListaTarjetas