import React, { useEffect, useState } from 'react';
import Atropos from "atropos/react";
import "atropos/atropos.css";
import { motion } from 'framer-motion';

//Imagenes
import cinta from '../../img/tarjetas/cinta.png';
import ReactHowler from 'react-howler';


//Sonidos
import desbloqueoTarjetaSonido from '../../sounds/tarjetas-desbloqueadas/desbloqueo-tarjeta.mp3';

const TarjetaDesbloqueada = (props) => {
    const [ opacidadTarjeta, setOpacidadTarjeta ] = useState("100%");
    const [ activarSonidoDeDesbloqueo, setActivarSonidoDeDesbloqueo ] = useState(false);
    const { fondo, personaje, nombre, tarjetaDesbloquear } = props;

    useEffect(() => {
        if(tarjetaDesbloquear === nombre){
            setOpacidadTarjeta("0%");
            setTimeout(() => {
                setOpacidadTarjeta("100%");
                setActivarSonidoDeDesbloqueo(true);
            }, 3000);
        }
    }, [tarjetaDesbloquear]);

    const desactivarSonido = () => {
        setActivarSonidoDeDesbloqueo(false);
    }

    //Estilos
    let animate = {
        opacity:["100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%", "100%","100%", "100%", "100%", "100%", "0%"],
        width: ["50%", "100%", "100%", "100%"],
        height: ["33%", "100%", "100%", "100%"],
        scale: [1, 0.5, 1, 0.5, 1, 0.8, 1, 0.8, 1],
        borderRadius: [ "100%", "10px"],
        rotate: [ 0, 120, 140 ,200, 360, 360, 360, 360],
        backgroundColor: ["#2a938", "white"]
    };

    let duration = {duration: 3};

    return (
        <div className='tarjeta-desbloqueada__div--container'>
            <ReactHowler onEnd={desactivarSonido} src={desbloqueoTarjetaSonido}  playing={activarSonidoDeDesbloqueo}/>
            <div className='tarjeta-desbloqueada__div--container--animacion'>
                <motion.div

                    transition={tarjetaDesbloquear === nombre ? (duration):({duration: 3})}

                    animate={tarjetaDesbloquear === nombre ? (animate):({opacity: "0%"})}

                    className='tarjeta-desbloqueada__div--animacion-desbloqueo' ></motion.div>
            </div>

            <Atropos style={{opacity: opacidadTarjeta, transition: "ease-in-out 0.3s"}}>
                <motion.img
                    transition={{duration: 1}}
                    animate={{
                        opacity: ["0%", "100%"]
                    }}
                    data-atropos-offset="-5"
                className='tarjeta-desbloqueada__img tarjeta-desbloqueada__img--fondo'  src={fondo} alt={`fondo ${nombre}`}/>
                <motion.img
                    transition={{duration: 1}}
                    animate={{
                        opacity: ["0%", "100%"]
                    }}
                    data-atropos-offset="5" className='tarjeta-desbloqueada__img tarjeta-desbloqueada__img--personaje'  src={personaje} alt={`personaje ${nombre}`}/>
                <motion.div
                    transition={{duration: 1}}
                    animate={{
                        opacity: ["0%", "100%"]
                    }}
                    data-atropos-offset="5" className='tarjeta-desbloqueada__div--container-nombre'>
                    <img src={cinta} alt='cinta'/>
                    <p>{nombre}</p>
                </motion.div>
            </Atropos>
        </div>
    )
}

export default TarjetaDesbloqueada;