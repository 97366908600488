import React from 'react';

//Imagenes
import logoPrincipal from '../../img/globales/logo-galileo.png';
import logoAplicativo from '../../img/globales/logo-aplicativo.png';

const Cabecero = () => {
    return (
        <header className='cabecero__header'>
            <div className='cabecero__div--container'>
                <div className='cabecero__div--logos'>
                    <img className='cabecero__img--logo-principal' src={logoPrincipal} alt='logo-principal'/>
                    <img className='cabecero__img--logo-aplicativo' src={logoAplicativo} alt='logo-aplicativo'/>
                </div>
                <div className='cabecero__div--adorno-bajo'></div>
            </div>
        </header>
    )
}

export default Cabecero