import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import cinta from '../../img/menu/cinta.png';

const JuegoDesbloqueado = (props) => {
    const [ zIndex, setZIndex] = useState(5);
    const { nombre, img, link } = props;

    const navegar = useNavigate();

    const irJuego = () => {
        setTimeout(() => {
            navegar(link);
        }, 700);

    }

    return (
        <div className='juegoDesbloqueado__div--container' onMouseEnter={() => {setZIndex(10)}} onMouseLeave={() => {setZIndex(5)}} style={{zIndex: zIndex}}>
            <motion.button
                className='juegoDesbloqueado__button'
                onClick={() => irJuego()}

                transition={{
                    duration: 0.3
                }}

                initial={{
                    borderRadius: "0px"
                }}

                whileHover={{ scale: 1.2, borderRadius: "100%"}}

                whileTap={{ scale: 0.8 }}
                >
                <img className='juegoDesbloqueado__img' src={img} alt={nombre}/>
                <img className='juegoDesbloqueado__img--cinta' src={cinta} alt='cinta-pirata'/>
                <p className='juegoDesbloqueado__p--nombre'>{nombre}</p>
            </motion.button>
        </div>
    )
}

export default JuegoDesbloqueado