import React from 'react';

const TransicionTarjeta = ({children}, props) => {
    const { tarjeta, tarjetaDesbloquear } = props;

    let estilos = {

    }

    if(tarjeta === tarjetaDesbloquear){
        estilos = {

        }
    }
    return (
        <div className='transicion__div--container'>
            {children}
        </div>
    )
}

export default TransicionTarjeta