import React from 'react'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

//Imagenes
import logoGalileo from '../img/globales/logo-galileo.png';
import logoAplicativo from '../img/globales/logo-aplicativo.png';

const Suscribete = (props) => {
    const { setUsuario } = props;


    const navegar = useNavigate();

    const cerrarSesion = () => {
        localStorage.removeItem('Token');
        setUsuario(null);
        navegar('/');
    }



    return (
        <motion.div
            transition={{duration: 1}}

            animate={{
                    opacity: ["0%", "100%"]
            }}


            className='suscribete__div--container'
            >
            <div className='suscribete__div-paquete-info'>
                <img className='suscribete__img--logo-galileo' src={logoGalileo} alt='logo-galileo'/>
                <img className='suscribete__img--logo-aplicativo'  src={logoAplicativo} alt='logo-aplicacion'/>
                <p className='suscribete__p--vencio-suscripcion'>SE VENCIO TU SUSCRIPCIÓN</p>
                <motion.a
                    href="https://wa.me/573007162596?text=Hola,%20quiero%20renovar%20la%20suscripción"

                    target="_blank"

                    transition={{duration: 0.3}}

                    whileHover={{
                        scale: 1.2,
                        backgroundColor: "white",
                        color: "#00CED1"
                    }}
                    className='suscribete__button--comprar-suscripcion' >Compra una nueva ahora</motion.a>
            </div>
            <motion.button
                transition={{duration: 0.3}}

                whileHover={{
                    scale: 1.2,
                    backgroundColor: "white",
                    color: "#00CED1"
                }}

                className='suscribete__button--cerrar-sesion' onClick={() => cerrarSesion()}>Cerrar sesión</motion.button>
        </motion.div>
    )
}

export default Suscribete