import { useEffect, useState } from 'react';

//Componentes
import NavBar from './components/NavBar';

//Paginas
import Menu from './pages/Menu';
import Suscribete from './pages/Suscribete';
import Premios from './pages/Premios';


//Importacion librerias
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { linkBackend } from './lib/linkBackend';


//Redux
import { Provider } from 'react-redux';
import store from './store';
import { RutaProtegida } from './components/RutaProtegida';
import Juego1 from './pages/Juegos/Juego1';
import Juego2 from './pages/Juegos/Juego2';
import CargaInicial from './pages/CargaInicial';
import IndexLoginPage from './pages/IndexLoginPage';
import { sumarMetrica } from './lib/funcionesGlobales';
import Ensayo from './components/Ensayo';





function App() {
  const [ usuario, setUsuario ] = useState(null);
  const [ loginIn, setLoginIn ] = useState(false);
  const [ cargaPagina, setcargaPagina ] = useState(true);
  const [ cuentaActiva, setCuentaActiva ] = useState(true);


  useEffect(() => {
    fetch(linkBackend('/api/login'), {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Token')
      }
      })
      .then(res => res.json())
      .then(res => {if(res.mensaje !== "Acceso denegado" && res.mensaje !== "Token expirado o incorrecto"){
        sumarMetrica("ingresos");
        setUsuario(res);
        setcargaPagina(false);
      }else{
        setcargaPagina(false);
      }})
      .catch(error => console.error(error));
  }, [loginIn]);



  useEffect(() => {
    let intervalo = setInterval(() => {
      if(usuario){
          try{
              let config = {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': localStorage.getItem('Token')
                  },body: JSON.stringify({
                      _id: usuario._id
                  })
              }

              fetch(linkBackend("/api/login-usuarios/datos-usuario"), config)
              .then(response => response.json())
              .then((data) => {
                  if(data){
                    setCuentaActiva(data.cuentaActiva);
                  }
              })
          }catch (error){
              console.error("Error al ejecutar el fetch.")
          }
    }
  }, 5000)

  return () => {
      clearInterval(intervalo);
  }
  }, [usuario]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<CargaInicial usuario={usuario}/>}/>

          <Route path='/ensayo' element={<Ensayo/>}/>

          {/* Ruta login administrador */}
          <Route path='/login' element={<IndexLoginPage usuario={usuario} setLoginIn={setLoginIn} loginIn={loginIn} cargaPagina={cargaPagina}/>}/>

          <Route path='/suscribete' element={<Suscribete setUsuario={setUsuario}/>}/>


          {/* Rutas protegidas */}
          <Route path='/home/*' element={<RutaProtegida isAllowed={!!usuario} redirecTo='/'>
            <NavBar setUsuario={setUsuario} usuario={usuario}/>
          </RutaProtegida>}>

            <Route path='menu' element={<RutaProtegida isAllowed={!!usuario && usuario.cuentaActiva === true && cuentaActiva === true} redirecTo='/suscribete'>
              <Menu usuario={usuario}/>
            </RutaProtegida>}/>

            <Route path='premios' element={<RutaProtegida isAllowed={!!usuario && usuario.cuentaActiva === true && cuentaActiva === true} redirecTo='/suscribete'>
              <Premios usuario={usuario}/>
            </RutaProtegida>}/>

            <Route path='juego1' element={<RutaProtegida isAllowed={!!usuario && usuario.cuentaActiva === true && cuentaActiva === true} redirecTo='/suscribete'>
              <Juego1 usuario={usuario}/>
            </RutaProtegida>}/>

            <Route path='juego2' element={<RutaProtegida isAllowed={!!usuario && usuario.cuentaActiva === true && cuentaActiva === true} redirecTo='/suscribete'>
              <Juego2/>
            </RutaProtegida>}/>

          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
