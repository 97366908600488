import React, { useEffect } from 'react'
import { desbloquearJuego, sumarMetricaJuegos } from '../../lib/funcionesGlobales'


const Juego1 = (props) => {
    const { usuario } = props

    useEffect(() => {
        sumarMetricaJuegos("juego1", "abierto");
    }, []);


    //Funcion para cuando termina el juego suma metricas y desblouqea mas suma puntos al jugador.
    const juegoFinalizado = () => {
        sumarMetricaJuegos("juego1", "terminado");

        desbloquearJuego(usuario._id, "juego2", 1000, 500, usuario.cuentaMasiva);
    }

    return (
        <div style={{position: "relative", top: "200px"}}>
            <div>Juego1</div>
            <button onClick={() => juegoFinalizado()}>Juego terminado</button>
            <button onClick={() => desbloquearJuego(usuario._id, "juego3", 1000, 500, usuario.cuentaMasiva)}>Juego terminado diferente</button>
        </div>
    )
}

export default Juego1