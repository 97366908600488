import React, { useEffect, useState } from 'react';
import { linkBackend } from '../../lib/linkBackend';
import { minijuegos1, minijuegos2, minijuegos3 } from '../../lib/ListaMinijuegos';
import Cabecero from './Cabecero';
import Categoria from './Categoria';
import Separador from './Separador';

//Imagenes Separadores
import separador1 from '../../img/Separadores/separador-1.png';
import separador2 from '../../img/Separadores/separador-2.png';

//Imagenes candados
import candadoBlanco from '../../img/minis-juegos/candado-blanco.png';
import candadoNegro from '../../img/minis-juegos/candado-negro.png';

//Imagenes Categorias
import categoria1 from '../../img/menu/categoria-1.png';
import categoria2 from '../../img/menu/categoria-2.png';
import categoria3 from '../../img/menu/categoria-3.png';
import categoria4 from '../../img/menu/categoria-4.png';



const ListaMiniJuegos = (props) => {
    const { usuario } = props;

    const [ datosUsuario, setDatosUsuario ] = useState(false);
    const [ carga, setCarga ] = useState(false);
    const [ error, setError ] = useState("");

    useEffect(() => {
        if(usuario.cuentaMasiva){
            setCarga(true);
            setDatosUsuario({aplicativosCompletos: JSON.parse(localStorage.getItem("aplicativosCompletos"))})
        }else{

        }


        let intervalo = setInterval(() => {
            if(usuario.cuentaMasiva){
                setCarga(true);
                setDatosUsuario({aplicativosCompletos: JSON.parse(localStorage.getItem("aplicativosCompletos"))})
            }else{
                try{
                    let config = {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('Token')
                        },body: JSON.stringify({
                            _id: usuario._id
                        })
                    }

                    fetch(linkBackend("/api/login-usuarios/datos-usuario"), config)
                    .then(response => response.json())
                    .then((data) => {
                        if(data){
                            setCarga(true);
                            setDatosUsuario(data);
                        }
                    })
                }catch (error){
                    setCarga(true);
                    setError(error);
                    console.error("Error al ejecutar el fetch.")
                }
            }
        }, 1000)

        return () => {
            clearInterval(intervalo);
        }
    }, []);

    return (
        <div style={{width: "100%"}}>
            <Cabecero/>
            <Categoria  minijuegos={minijuegos1} carga={carga} datosUsuario={datosUsuario} error={error} desbloqueado={["juego1"]} categoria={categoria1} colorFondo="#FFA500" candado={candadoBlanco}/>
            <Separador img={separador1} lado={"left"}/>
            <Categoria  minijuegos={minijuegos2} carga={carga} datosUsuario={datosUsuario} error={error} desbloqueado={["juego2-1"]} categoria={categoria2} colorFondo="#00CED1" colorBloqueo="#ff0000" candado={candadoBlanco}/>
            <Separador img={separador2} lado={"right"}/>
            <Categoria  minijuegos={minijuegos3} carga={carga} datosUsuario={datosUsuario} error={error} desbloqueado={["juego3-1"]} categoria={categoria3} colorFondo="#232323" colorBloqueo="#ffffff" candado={candadoNegro}/>
        </div>
    )
}

export default ListaMiniJuegos