import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';


//Imagenes
import cerrarSesionImg from '../../img/Navbar/cerrar-sesion-bl.png';
import menuPrincipal from '../../img/Navbar/icono-menu.png';
import cofre from '../../img/Navbar/cofre.png';
import premios from '../../img/Navbar/icono-premios.png';
import adorno1 from '../../img/Navbar/adorno-fondo1.png';


import { linkBackend } from '../../lib/linkBackend';

const NavBarDesktop = (props) => {
    const [ datosUsuario, setDatosUsuario ] = useState(false);
    const [ carga, setCarga ] = useState(false);
    const [ error, setError ] = useState("");
    const { usuario, cerrarSesion } = props;

    const hoverTrueContainer = {
        backgroundColor: "#FFA500",
        width: "200px",
        height: "200px",
        backgroundImage: `url(${adorno1})`,
    }

    const hoverFalseContainer = {
        backgroundColor: "#00CED1",
        width: "200px",
    }

    const hoverTrueBotones = {
        width: "90%",
        height: "60px",
    }

    const hoverFalseBotones = {
        width: "0px",
        height: "0px",
    }


    const [ estilosHover, setEstilosHover] = useState(hoverFalseContainer);
    const [ estilosHoverBotones, setEstilosHoverBotones] = useState(hoverFalseBotones);

    //Creamos un efecto con un fetch en intervalo de 1 segundo que pregunta si la cuenta es tipo cuenta masiva y toma los puntos del localStorage si lo es y si no toma los puntos de la base  de datos.
    useEffect(() => {
        let intervalo = setInterval(() => {
            if(usuario.cuentaMasiva){
                setCarga(true);
                setDatosUsuario({puntosSeguimiento: localStorage.getItem('puntosSeguimiento') })
            }else{
                try{
                    let config = {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('Token')
                        },body: JSON.stringify({
                            _id: usuario._id
                        })
                    }

                    fetch(linkBackend("/api/login-usuarios/datos-usuario"), config)
                    .then(response => response.json())
                    .then((data) => {
                        if(data){
                            setCarga(true);
                            setDatosUsuario(data);
                        }
                    })
                }catch (error){
                    setCarga(true);
                    setError(error);
                    console.error("Error al ejecutar el fetch.")
                }
            }}, 1000)

        return () => {
            clearInterval(intervalo);
        }
    }, []);


    let componentePuntos;
    //Creamos las tarjetas segun la cantidad de usuarios en la base de datos.
    if(error){
        console.log("carga datos error", datosUsuario);
        componentePuntos = [<h2>Error: {error.message}</h2>];
    }else if(!carga){
        componentePuntos = [<div className=''>Cargando...</div>];
    }else if(carga){
        componentePuntos = <p>{datosUsuario.puntosSeguimiento}</p>
    };


    const hoverInteractivo = (boolean) => {
        if(boolean){
            setEstilosHover(hoverTrueContainer);
            setEstilosHoverBotones(hoverTrueBotones);
        }else if(!boolean){
            setEstilosHover(hoverFalseContainer);
            setEstilosHoverBotones(hoverFalseBotones);
        }
    }


    return (
        <div className='nav-bar-desktop__div--container'>
            <div className='nav-bar-desktop__div--fondo-animado'></div>
            <div className='nav-bar-desktop__div--paquete-opciones'>
                <NavLink to='/home/menu'>
                    <motion.img

                        transition={{
                            duration: 0.1
                        }}

                        whileHover={{ scale: 1.2, borderRadius: "25%", backgroundColor: "#FFA500"}}

                        whileTap={{ scale: 0.5, backgroundColor: "#00CED1" }}

                        className='nav-bar-desktop__img--menu-principal'
                        src={menuPrincipal}
                        alt='menu-principal'
                    />
                </NavLink>
                <div onMouseEnter={() => hoverInteractivo(true)} onMouseLeave={() => hoverInteractivo(false)} style={estilosHover} className='nav-bar-desktop__div--container--cajon-animado'>
                    <div className='nav-bar-desktop__div--nombre-puntos'>
                        <p className='nav-bar-desktop__div--usuario'>{usuario.usuario}</p>
                        <div className='nav-bar-desktop__div--paquete-puntos'>
                            <img width="20px" src={cofre} alt='cofre'/>
                            <p>{componentePuntos}</p>

                        </div>
                    </div>
                    <div className='nav-bar-desktop__div--container-botones-salir-premios' style={estilosHoverBotones}>
                        <NavLink to='/home/premios'>
                            <motion.img

                                transition={{
                                    duration: 0.1
                                }}

                                whileHover={{ borderRadius: "25%", backgroundColor: "#FFA500"}}

                                whileTap={{ scale: 0.5, backgroundColor: "#00CED1" }}

                                className='nav-bar-desktop__img--premios'
                                src={premios}
                                alt='premios'
                            />
                        </NavLink>
                        <button className='nav-bar-desktop__button--cerrar-container' onClick={() => cerrarSesion()}>
                            <motion.img

                            transition={{
                                duration: 0.1
                            }}

                            whileHover={{ borderRadius: "25%", backgroundColor: "#FFA500"}}

                            whileTap={{ scale: 0.5, backgroundColor: "#00CED1" }} className='nav-bar-desktop__img--cerrar-sesion' src={cerrarSesionImg} alt='cerrar-sesion'/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBarDesktop