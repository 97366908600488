import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { linkBackend } from "../../lib/linkBackend";
import Atropos from "atropos/react";
import "atropos/css";

//Imagenes
import imagen1 from "../../img/Index-login/diseno-3d-1.png";
import imagen2 from "../../img/Index-login/diseno-3d-2.png";
import imagen3 from "../../img/Index-login/diseno-3d-3.png";
import imagen4 from "../../img/Index-login/diseno-3d-4.png";
import logoPrincipal from "../../img/Index-login/logo-galileo.png";
import mascotaGalileo from "../../img/Index-login/logo-mascota-galileo.png";


const IndexDesktop = (props) => {
    const [errorAutenticacion, setErrorAutenticacion] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [mensajeCarga, setMensajeCarga] = useState("");
  
    const { usuario, setLoginIn, loginIn } = props;
  
    let navegar = useNavigate();
  
    //Verificamos si hay un usuario logueado en el navegador y vamos directamente al admin.
    useEffect(() => {
      if (usuario) {
        navegar("/home/menu");
      }
    }, [usuario]);
  
    //Definimos si en el estado errorAutenticacion hay un mensaje que despues de 4 segundos vuelva a ser null para que desaparezca de la pantalla.
    if (errorAutenticacion) {
      setTimeout(() => {
        setErrorAutenticacion(null);
      }, 4000);
    }
  
    useEffect(() => {
      if (cargando === true && errorAutenticacion === null) {
        setMensajeCarga(<div className="loader" id="loader"></div>);
      } else if (errorAutenticacion) {
        setMensajeCarga("");
        setCargando(false);
      }
    }, [cargando, errorAutenticacion]);
  
    return (
      <>
        <div className="index__div--container">
          <div className="">
            <Formik
              initialValues={{
                usuario: "",
                contrasena: "",
              }}
              validate={(valores) => {
                let errores = {};
  
                if (!valores.usuario) {
                  errores.usuario = "Introduzca un usuario valido";
                }
  
                if (!valores.contrasena) {
                  errores.contrasena = "Introduzca una contraseña correcta";
                }
  
                return errores;
              }}
              onSubmit={async (valores) => {
                if (localStorage.getItem("usuario") !== valores.usuario) {
                  localStorage.setItem("_id", "Vacio");
                  localStorage.setItem("usuario", "Vacio");
                  localStorage.setItem("nombre", "Vacio");
                  localStorage.setItem("puntosSeguimiento", 0);
                  localStorage.setItem(
                    "aplicativosCompletos",
                    JSON.stringify([])
                  );
                  localStorage.setItem(
                    "tarjetasDesbloqueadas",
                    JSON.stringify([])
                  );
                }
                setCargando(true);
                try {
                  let config = {
                    method: "POST",
                    headers: {
                      accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      usuario: valores.usuario,
                      contrasena: valores.contrasena,
                    }),
                  };
                  let res = await fetch(
                    linkBackend("/api/login-usuarios/autenticacion"),
                    config
                  );
                  let json = await res.json();
                  let authorizationValue = res.headers.get("Authorization");
  
                  if (json.mensaje === "Este Usuario no existe") {
                    setErrorAutenticacion("Este Usuario no existe");
                  } else if (
                    json.mensaje === "Usuario y contraseña incorrectos"
                  ) {
                    setErrorAutenticacion("Usuario y contraseña incorrectos");
                  } else if (
                    json.mensaje === "Usuario autenticado correctamente"
                  ) {
                    //Verificar si hubo un acceso para que traiga el usuario al estado para acceder a la pagina.
                    if (loginIn === false) {
                      setLoginIn(true);
                    } else if (loginIn === true) {
                      setLoginIn(false);
                    }
  
                    //Guardando el token en la store del navegador.
                    localStorage.setItem("Token", authorizationValue);
  
                    setTimeout(() => {
                      navegar("/home/menu");
                    }, 1000);
                  }
                } catch (error) {
                  console.error("Error con el login", error);
                }
              }}
            >
              {({ errors }) => (
                <Form className="">
                  {mensajeCarga ? (
                    mensajeCarga
                  ) : (
                    <>
                      <div className="container-3d">
                        <div class="container">
                          <Atropos className="atropos-component">
                            <div className="atropos">
                              <div className="atropos-scale">
                                <div className="atropos-rotate">
                                  <div className="atropos-inner">
                                    <img
                                      className="atropos-spacer"
                                      data-atropos-offset="-2"
                                      data-atropos-opacity="0.3;1"
                                      src={imagen1}
                                      alt="imagen-1"
                                    />
                                    <img
                                      data-atropos-offset="-2"
                                      className="atropos-3d atropos-3d-1"
                                      src={imagen1}
                                      alt="imagen-1"
                                    />
                                    <img
                                      data-atropos-offset="-1"
                                      className="atropos-3d atropos-3d-2"
                                      src={imagen2}
                                      alt="imagen-2"
                                    />
                                    <img
                                      data-atropos-offset="0"
                                      className="atropos-3d atropos-3d-3"
                                      src={imagen3}
                                      alt="imagen-3"
                                    />
                                    <img
                                      data-atropos-offset="1"
                                      className="atropos-3d atropos-3d-4"
                                      src={imagen4}
                                      alt="imagen-4"
                                    />
                                    <div
                                      className="logo-galileo"
                                      data-atropos-offset="2"
                                    >
                                      <div
                                        data-atropos-offset="2"
                                        className="imagen-logoPrincipal"
                                      ></div>
                                    </div>
                                    <div
                                      className="contenedor-fields"
                                      data-atropos-offset="2"
                                    >
                                      <div className="">
                                        <Field
                                          data-atropos-offset="2"
                                          type="text"
                                          id="usuario"
                                          name="usuario"
                                          placeholder="Introducir usuario"
                                          className="index__field--input index__field--input-usuario"
                                        />
                                      </div>
                                      <img
                                        data-atropos-offset="2"
                                        src={mascotaGalileo}
                                        alt="nautilus"
                                      />
                                      <div className="">
                                        <Field
                                          data-atropos-offset="2"
                                          type="password"
                                          id="contrasena"
                                          name="contrasena"
                                          placeholder="Introducir contraseña"
                                          className="index__field--input index__field--input-contrasena"
                                        />
                                      </div>
                                    </div>
  
                                    <div
                                      data-atropos-offset="5"
                                      className="index__div--container-button-submit"
                                    >
                                      <button type="submit">Entrar</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Atropos>
                        </div>
                      </div>
  
                      <ErrorMessage
                        name="contrasena"
                        component={() => (
                          <h4 className="index__h4--error index__h4--contrasena">
                            {errors.contrasena}
                          </h4>
                        )}
                      />
                      <ErrorMessage
                        name="usuario"
                        component={() => (
                          <h4 className="index__h4--error index__h4--usuario">
                            {errors.usuario}
                          </h4>
                        )}
                      />
                      {errorAutenticacion ? (
                        <h4 className="index__h4--error index__h4--usuario">
                          {errorAutenticacion}
                        </h4>
                      ) : (
                        ""
                      )}
                      {mensajeCarga}
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  };
  

export default IndexDesktop