import React from 'react';

const JuegoBloqueado = (props) => {
    const { nombre, img, colorBloqueo, candado } = props;

    return (
        <div className='juegoBloqueado__div--container' style={{backgroundColor: colorBloqueo}}>
            <img className='juegoBloqueado__img' src={img} alt={nombre}/>
            <img className='juegoBloqueado__img-candado' src={candado} alt="candado"/>
        </div>
    )
}

export default JuegoBloqueado;