import React from 'react';

const Separador = (props) => {

    const { img, lado } = props;

    let derecho, izquierdo;

    if( lado === "right" ){
        derecho = "50px";
        izquierdo = "auto";
    }else if( lado === "left" ){
        derecho = "auto";
        izquierdo = "50px";
    }

    

    return (
        <div className='separador__div--container'>
            <img className='separador__img--separador' src={img} alt='icono-separador' style={{left: izquierdo, right: derecho}}/>
        </div>
    )
}

export default Separador;