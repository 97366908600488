import React from 'react';
import { motion } from 'framer-motion';

//Imagenes
import logo from '../../img/Index-login/logo-galileo.png';
import personaje from '../../img/Index-login/personaje-aniamcion-movil.png';
import mar from '../../img/Index-login/mar-animacion movil.png';


const AnimacionLoginMovil = () => {
    return (
        <motion.div
            transition={{duration: 7}}

            animate={{
                    opacity: [ "100%", "100%", "100%", "100%", "100%", "100%", "0%"]
            }}

            className='animacion-login__div--container'
        >
            <motion.img
                transition={{duration: 5}}

                animate={{
                    x: [ 0, 0, 0, 0, -500 ]
                }}

                className='animacion-login__img--logo'
                src={logo}
                alt='logo'
            />

            <motion.img
                transition={{duration: 5}}

                animate={{
                    rotate: [ 20, -20, 20, -20, 20, -20 ],
                    x: [ 0, 0, 0, 0, 500 ]
                }}

                className='animacion-login__img--barco'
                src={personaje}
                alt='barco-pirata'
            />

            <motion.img
                // transition={{duration: 5}}

                // animate={{
                //     x: [ 20, -20, 20, -20, 20, -20 ],
                //     y: [ 0, 0, 0, 0, 0, 0, 500]
                // }}

                className='animacion-login__img--mar'
                src={mar}
                alt='mar'
            />
        </motion.div>
    )
}

export default AnimacionLoginMovil