
import juego1 from '../img/minis-juegos/juego-1.png';
import juego2 from '../img/minis-juegos/juego-2.png';
import juego3 from '../img/minis-juegos/juego-3.png';
import juego4 from '../img/minis-juegos/juego-4.png';
import juego5 from '../img/minis-juegos/juego-5.png';
import juego6 from '../img/minis-juegos/juego-6.png';


let minijuegos1 = [
    {
        juego: "juego1",
        imagen: juego1,
        link: "/home/juego1"
    },
    {
        juego: "juego2",
        imagen: juego2,
        link: "/home/juego2"
    },
    {
        juego: "juego3",
        imagen: juego3,
        link: "/home/juego3"
    },
    {
        juego: "juego4",
        imagen: juego4,
        link: "/home/juego4"
    },
    {
        juego: "juego5",
        imagen: juego5,
        link: "/home/juego5"
    },
    {
        juego: "juego6",
        imagen: juego6,
        link: "/home/juego6"
    },
];

let minijuegos2 = [
    {
        juego: "juego2-1",
        imagen: juego1,
        link: "/home/juego1"
    },
    {
        juego: "juego2-2",
        imagen: juego1,
        link: "/home/juego2"
    },
    {
        juego: "juego2-3",
        imagen: juego1,
        link: "/home/juego3"
    },
    {
        juego: "juego2-4",
        imagen: juego1,
        link: "/home/juego4"
    },
    {
        juego: "juego2-5",
        imagen: juego1,
        link: "/home/juego5"
    },
    {
        juego: "juego2-6",
        imagen: juego1,
        link: "/home/juego6"
    },
];

let minijuegos3 = [
    {
        juego: "juego3-1",
        imagen: juego1,
        link: "/home/juego1"
    },
    {
        juego: "juego3-2",
        imagen: juego1,
        link: "/home/juego2"
    },
    {
        juego: "juego3-3",
        imagen: juego1,
        link: "/home/juego3"
    },
    {
        juego: "juego3-4",
        imagen: juego1,
        link: "/home/juego4"
    },
    {
        juego: "juego3-5",
        imagen: juego1,
        link: "/home/juego5"
    },
    {
        juego: "juego3-6",
        imagen: juego1,
        link: "/home/juego6"
    },
];


export { minijuegos1, minijuegos2, minijuegos3 };