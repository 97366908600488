import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';


//Imagenes
import hamburguesa from '../../img/Navbar/hamburguesa.png';
import cerrar from '../../img/Navbar/cerrar.png';
import menuPrincipal from '../../img/Navbar/icono-menu.png';
import premios from '../../img/Navbar/icono-premios.png';
import cerrarSesionImg from '../../img/Navbar/cerrar-sesion-bl.png';
import cofre from '../../img/Navbar/cofre.png';
import { linkBackend } from '../../lib/linkBackend';



const NavBarMovil = (props) => {
    const [ menuAbierto, setMenuAbierto ] = useState(true);
    const [ transicionBoton, setTransicionBoton] = useState("1");
    const [ aperturaMenu, setAperturaMenu ] = useState("0vh");
    const [ datosUsuario, setDatosUsuario ] = useState(false);
    const [ carga, setCarga ] = useState(false);
    const [ error, setError ] = useState("");
    const { usuario, cerrarSesion } = props;

    const procesarMenu = () => {
        if(menuAbierto){
            setMenuAbierto(false);
            setTransicionBoton("0.5");
            setAperturaMenu("calc(100vh - 80px)");
            setTimeout(() => {
                setTransicionBoton("1");
            }, 500);
        }else if(!menuAbierto){
            setMenuAbierto(true);
            setTransicionBoton("0.5");
            setAperturaMenu("0vh");
            setTimeout(() => {
                setTransicionBoton("1");
            }, 500);
        }
    }


    //Creamos un efecto con un fetch en intervalo de 1 segundo que pregunta si la cuenta es tipo cuenta masiva y toma los puntos del localStorage si lo es y si no toma los puntos de la base  de datos.
    useEffect(() => {
        let intervalo = setInterval(() => {
            if(usuario.cuentaMasiva){
                setCarga(true);
                setDatosUsuario({puntosSeguimiento: localStorage.getItem('puntosSeguimiento') })
            }else{
                try{
                    let config = {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('Token')
                        },body: JSON.stringify({
                            _id: usuario._id
                        })
                    }

                    fetch(linkBackend("/api/login-usuarios/datos-usuario"), config)
                    .then(response => response.json())
                    .then((data) => {
                        if(data){
                            setCarga(true);
                            setDatosUsuario(data);
                        }
                    })
                }catch (error){
                    setCarga(true);
                    setError(error);
                    console.error("Error al ejecutar el fetch.")
                }
            }}, 1000)

        return () => {
            clearInterval(intervalo);
        }
    }, []);


    let componentePuntos;
    //Creamos las tarjetas segun la cantidad de usuarios en la base de datos.
    if(error){
        console.log("carga datos error", datosUsuario);
        componentePuntos = [<h2>Error: {error.message}</h2>];
    }else if(!carga){
        componentePuntos = [<div className=''>Cargando...</div>];
    }else if(carga){
        componentePuntos = <p>{datosUsuario.puntosSeguimiento}</p>
    };




    return (
        <>
            <div className='navbar-movil__div--container'>
                <div className='navbar-movil__div--fondo-animado'></div>
                <button onClick={() => procesarMenu()} className='navbar-movil__button--hamburguesa'>
                    {menuAbierto ? (<img style={{opacity: transicionBoton}} className='navbar-movil__img--hamburguesa' src={hamburguesa} alt='hamburguesa'/>):(<img style={{opacity: transicionBoton}} className='navbar-movil__img--cerrar' src={cerrar} alt='cerrar'/>)}
                </button>

                <div className='navbar-movil__div--container-usuario-puntos'  style={{width: carga ? "230px":"0px"}}>
                    <div className='navbar-movil__div--paquete-puntos'>
                        <img className='navbar-movil__img--cofre' src={cofre} alt='cofre'/>
                        <p className="navbar-movil__p--puntos">{componentePuntos}</p>
                    </div>
                    <p className='navbar-movil__p--usuario'>{usuario.usuario}</p>
                </div>


            </div>
            <div style={{height: aperturaMenu}} className='navbar-movil__div--container-opciones'>
                <div className='navbar-movil__div--grid'>
                    <NavLink to='/home/menu'>
                        <button onClick={() => procesarMenu()} className='navbar-movil__button-opcion navbar-movil__button-opcion-azul'>
                            <img src={menuPrincipal} alt='menu-principal'/>
                        </button>
                    </NavLink>
                    <NavLink to='/home/premios'>
                        <button onClick={() => procesarMenu()}  className='navbar-movil__button-opcion navbar-movil__button-opcion-naranja'>
                            <img src={premios} alt='premios'/>
                        </button>
                    </NavLink>
                    <button className='navbar-movil__button-opcion navbar-movil__button-opcion-azul' onClick={() => cerrarSesion()}>
                        <img src={cerrarSesionImg} alt='cerrar sesión'/>
                    </button>
                </div>
            </div>
        </>
    )
}
export default NavBarMovil