import React from 'react'
import JuegoDesbloqueado from './JuegoDesbloqueado';
import JuegoBloqueado from './JuegoBloqueado';

const Categoria = (props) => {
    const { minijuegos, desbloqueado, carga, datosUsuario, error, categoria, colorFondo, colorBloqueo, candado } = props;


    let componenteJuegos;
    //Creamos las tarjetas segun la cantidad de usuarios en la base de datos.
    if(datosUsuario !== null){
        if(error){
            componenteJuegos = [<h2>Error: {error.message}</h2>];
        }else if(!carga){
            componenteJuegos = [<div className=''>Cargando... tarjetas</div>];
        }else if(carga){
            componenteJuegos = minijuegos.map(minijuego => {
                return (datosUsuario.aplicativosCompletos.includes(minijuego.juego) || desbloqueado.includes(minijuego.juego)) ? (<JuegoDesbloqueado nombre={minijuego.juego} img={minijuego.imagen} link={minijuego.link}/>):(<JuegoBloqueado nombre={minijuego.juego} img={minijuego.imagen} colorBloqueo={colorBloqueo} candado={candado}/>)
            })
        };
    };




    return (
        <div className='categoria__div--container' style={{backgroundColor: `${colorFondo}`}}>
        <div className='categoria__div--container-img'>
            <img className='categoria__img--letrero-categoria' src={categoria} alt='categoria'/>
        </div>
            <div className='categoria__div--grid'>
                {componenteJuegos}
            </div>
        </div>
    )
}

export default Categoria